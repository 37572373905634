import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';
// pages
import Home from './components/Home';
import Home2 from './components/Home2';
import Home3 from './components/Home3';
import Home3_Header3 from './components/Header_style_03';
import Aboutus_01 from './components/pages/Aboutus_01';
import Services_02 from './components/pages/Services_02';
import Faq from './components/pages/Faq';
import Contact_01 from './components/pages/Contact_01';
import Contact_02 from './components/pages/Contact_02';
import Error from './components/pages/Error';
import Element from './components/pages/Element';
import Project_style1 from './components/project/Project_style1';
import Single_style1 from './components/project/Single_style1';
import Single_style2 from './components/project/Single_style2';
import Single_style3 from './components/project/Single_style3';
import Blog_top_image from './components/blog/Blog_top_image';
import ScrollToTop from './components/layout/Gotop';
import ServicePage from './components/services/ServicePage';

const history = createBrowserHistory();

const services = [
  {
    path: '/web_development_and_design',
    title: 'Web Development and Design',
    description: 'We specialize in creating stunning, responsive websites tailored to your business needs. Our designs are optimized for performance and search engine visibility, ensuring that your website not only looks great but also functions flawlessly across all devices. Our team focuses on user experience, making your website intuitive and engaging. We stay updated with the latest design trends and technologies to provide modern, high-performing websites. We also offer continuous support and updates to keep your site running smoothly. Our expertise includes e-commerce, portfolio, and informational websites. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional web designs that drive business growth and success.',
    image: 'images/services-01.jpg'
  },
  {
    path: '/rpa_implementation_and_customization',
    title: 'RPA Implementation and Customization',
    description: 'Our RPA solutions automate repetitive tasks, enhancing efficiency and reducing operational costs. We tailor RPA systems to your specific business needs, ensuring seamless integration with your existing systems. Our team provides end-to-end RPA implementation services, from assessment and planning to deployment and maintenance. Our solutions are scalable and adaptable, designed to grow with your business. We offer continuous support and maintenance to ensure optimal performance. Our RPA systems improve accuracy and compliance, helping you achieve significant time and cost savings. Our expertise spans various industries, delivering customized RPA solutions that drive business innovation and efficiency.',
    image: 'images/services-02.jpg'
  },
  {
    path: '/mobile_app_development',
    title: 'Mobile App Development',
    description: 'We develop user-friendly mobile apps for both Android and iOS platforms. Our apps are designed to deliver an excellent user experience, with intuitive interfaces and seamless performance. We focus on performance, security, and scalability, ensuring that your app can grow with your business. Our team handles everything from design and development to testing and deployment. We offer continuous support and updates to keep your app running smoothly. Our expertise includes e-commerce, social media, and utility apps. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional mobile apps that drive business growth and success.',
    image: 'images/services-03.jpg'
  },
  {
    path: '/e-commerce_development',
    title: 'E-Commerce Development',
    description: 'We build secure and scalable e-commerce solutions to grow your business online. Our designs are optimized for performance and search engine visibility, ensuring that your online store not only looks great but also functions flawlessly across all devices. We focus on user experience, making your store intuitive and engaging for customers. Our team handles everything from design and development to payment gateway integration and security. We offer continuous support and updates to keep your store running smoothly. Our expertise includes various e-commerce platforms. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional e-commerce solutions that drive business growth and success.',
    image: 'images/services-04.jpg'
  },
  {
    path: '/api_development_and_3rd_party_integration',
    title: 'API Development and 3rd Party Integration',
    description: 'We integrate third-party services seamlessly with robust APIs. Our API solutions enhance your system\'s functionality and performance, allowing you to connect with various external services and applications. We provide end-to-end API development and integration services, from planning and design to implementation and testing. Our team ensures secure and scalable API solutions that meet your business needs. We offer continuous support and maintenance to keep your APIs running smoothly. Our expertise includes various API protocols and standards. We deliver high-quality, professional API solutions that drive business innovation and efficiency.',
    image: 'images/services-05.jpg'
  },
  {
    path: '/custom_software_development',
    title: 'Custom Software Development',
    description: 'We create custom software solutions tailored to your specific business requirements. Our solutions are designed to enhance efficiency and productivity, addressing your unique challenges and opportunities. We provide end-to-end software development services, from initial consultation and design to development and deployment. Our team ensures secure and scalable software solutions that grow with your business. We offer continuous support and updates to keep your software running smoothly. Our expertise spans various industries and technologies. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional software solutions that drive business growth and success.',
    image: 'images/services-06.jpg'
  },
  {
    path: '/saas_-_software_as_a_service',
    title: 'SaaS - Software as a Service',
    description: 'We deliver scalable and flexible SaaS solutions for diverse business needs. Our solutions are designed to enhance efficiency and productivity, offering a cost-effective alternative to traditional software. We provide end-to-end SaaS development services, from initial consultation and design to development and deployment. Our team ensures secure and scalable SaaS solutions that grow with your business. We offer continuous support and updates to keep your SaaS applications running smoothly. Our expertise spans various industries and technologies. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional SaaS solutions that drive business growth and success.',
    image: 'images/services-07.jpg'
  },
  {
    path: '/automated_testing_and_qa_services',
    title: 'Automated Testing and QA Services',
    description: 'We ensure the highest quality of your software with our automated testing services. Our solutions enhance efficiency and reduce operational costs by identifying and fixing issues early in the development process. We provide end-to-end testing and QA services, from initial planning and test case design to execution and reporting. Our team ensures reliable and accurate testing solutions that meet your business needs. We offer continuous support and updates to keep your software running smoothly. Our expertise includes various testing tools and methodologies. We deliver high-quality, professional testing and QA solutions that drive business growth and success.',
    image: 'images/services-08.jpg'
  },
  {
    path: '/business_process_automation',
    title: 'Business Process Automation',
    description: 'We streamline and optimize your business processes with automation. Our solutions enhance efficiency and reduce operational costs, allowing you to focus on your core business activities. We provide end-to-end business process automation services, from initial consultation and design to implementation and maintenance. Our team ensures reliable and scalable automation solutions that meet your business needs. We offer continuous support and updates to keep your processes running smoothly. Our expertise spans various industries and technologies. We integrate advanced features and functionalities to meet your unique requirements. Our goal is to deliver high-quality, professional business process automation solutions that drive business growth and success.',
    image: 'images/services-09.jpg'
  },
  {
    path: '/crm_implementation_and_customization',
    title: 'CRM Implementation and Customization',
    description: 'We implement and customize CRM systems to boost customer relationships. Our solutions enhance efficiency and productivity, allowing you to manage customer interactions and data effectively. We provide end-to-end CRM implementation services, from initial consultation and system selection to customization and deployment. Our team ensures reliable and scalable CRM solutions that meet your business needs. We offer continuous support and updates to keep your CRM system running smoothly. Our expertise spans various CRM platforms and technologies. We deliver high-quality, professional CRM solutions that drive business growth and success.',
    image: 'images/services-10.jpg'
  },
  {
    path: '/cyber_security_services',
    title: 'Cyber Security Services',
    description: 'We protect your digital assets with comprehensive cybersecurity solutions. Our solutions enhance security and reduce operational risks, safeguarding your business from cyber threats. We provide end-to-end cybersecurity services, from initial assessment and strategy development to implementation and monitoring. Our team ensures reliable and scalable cybersecurity solutions that meet your business needs. We offer continuous support and updates to keep your systems secure. Our expertise includes various cybersecurity tools and methodologies. We deliver high-quality, professional cybersecurity solutions that drive business growth and success.',
    image: 'images/services-11.jpg'
  },
  {
    path: '/devops,_cicd_and_cloud_services',
    title: 'DevOps, CICD and Cloud Services',
    description: 'We enhance your IT infrastructure with our DevOps and cloud services. Our solutions improve efficiency and reduce operational costs, enabling seamless development and deployment processes. We provide end-to-end DevOps and cloud services, from initial consultation and strategy development to implementation and management. Our team ensures reliable and scalable solutions that meet your business needs. We offer continuous support and updates to keep your infrastructure running smoothly. Our expertise includes various DevOps and cloud tools and methodologies. We deliver high-quality, professional DevOps and cloud services that drive business growth and success.',
    image: 'images/services-12.jpg'
  },
  {
    path: '/database_management_and_optimization',
    title: 'Database Management and Optimization',
    description: 'We manage and optimize your databases for peak performance. Our solutions enhance efficiency and reduce operational costs, ensuring that your data is secure and accessible. We provide end-to-end database management services, from initial assessment and design to implementation and monitoring. Our team ensures reliable and scalable database solutions that meet your business needs. We offer continuous support and updates to keep your databases running smoothly. Our expertise includes various database tools and methodologies. We deliver high-quality, professional database management and optimization services that drive business growth and success.',
    image: 'images/services-13.jpg'
  },
  {
    path: '/data_analyst_and_visualization',
    title: 'Data Analyst and Visualization',
    description: 'We transform your data into actionable insights with our analysis and visualization services. Our solutions enhance efficiency and reduce operational costs, enabling informed decision-making. We provide end-to-end data analysis and visualization services, from initial assessment and data collection to analysis and reporting. Our team ensures reliable and scalable data solutions tailored to your specific business needs. We use advanced tools and methodologies to turn complex data into clear, understandable visualizations. Our services help you uncover trends, patterns, and insights that drive strategic decisions. We offer continuous support and updates to keep your data insights relevant and valuable. Our goal is to deliver high-quality, professional data analysis and visualization services that drive business growth and success.',
    image: 'images/services-14.jpg'
  },
  {
    path: '/it_support_maintenance',
    title: 'IT Support Maintenance',
    description: 'We provide reliable IT support and maintenance services to keep your systems running smoothly. Our team of experienced professionals is available 24/7 to address any IT issues that may arise, ensuring minimal downtime and maximum productivity. We offer proactive monitoring and maintenance to prevent potential problems before they occur. Our services include hardware and software support, network management, and data backup and recovery. We tailor our support services to meet your specific business needs, providing flexible solutions that scale with your business. Our goal is to ensure your IT infrastructure operates efficiently and effectively, so you can focus on your core business activities. Our comprehensive IT support covers everything from troubleshooting to system upgrades, ensuring your business stays up and running without interruption.',
    image: 'images/services-15.jpg'
  }
];

function App() {
  return (
    <div className="page">
      <Router history={history}>  
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>   
        <ScrollToTop />
            <Route exact path='/' component={ Home } /> 
            <Route path='/home2' component={ Home2 } /> 
            <Route path='/home3' component={ Home3 } />
            <Route path='/header_style_03' component={ Home3_Header3 } />
            <Route path="/about-us" component={Aboutus_01} />
            <Route path="/services" component={Services_02} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact_01" component={Contact_01} />
            <Route path="/contact-us" component={Contact_02} />
            <Route path="/error" component={Error} />
            <Route path="/element" component={Element} />
            <Route path="/industries" component={Project_style1} />
            <Route path="/single_style1" component={Single_style1} />
            <Route path="/single_style2" component={Single_style2} />
            <Route path="/single_style3" component={Single_style3} />
            <Route path="/blog" component={Blog_top_image} />
            {services.map(service => (
                    <Route
                        key={service.path}
                        path={service.path}
                        render={props => (
                            <ServicePage
                                {...props}
                                title={service.title}
                                description={service.description}
                                image={service.image}
                            />
                        )}
                    />
                ))}
        <Footer />
      </Router>  
    </div> 
  );
}

export default App;
