import React, { Component } from 'react';
import {createBrowserHistory} from 'history';
import {BrowserRouter as Router, Route} from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li><a href={process.env.PUBLIC_URL + '/about-us'}>About Us</a></li>

                    <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/Services'}>services</a>
                        {/* <ul className="menu-dropdown">
                            <li><a href={process.env.PUBLIC_URL + '/it_consultancy'}>Web Development and Design</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/digital_services'}>RPA Implementation and Customization</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/advisory_services'}>Mobile App Development</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/data_structuring'}>E-Commerce Development</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/experience_design'}>API Development and 3rd Party Integration</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/content_engineering'}>Custom Software Development</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/it_consultancy'}>SaaS - Software as a Service</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/digital_services'}>Automated Testing and QA Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/advisory_services'}>Business Process Automation</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/data_structuring'}>CRM Implementation and Customization</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/experience_design'}>Cyber Security Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/content_engineering'}>DevOps, CICD and  Could Services</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/experience_design'}>Database Management and Optimization</a></li>
                            <li><a href={process.env.PUBLIC_URL + '/content_engineering'}>Data Analyst and Visualization</a></li>
                        </ul> */}
                    </li>
                    <li><a href={process.env.PUBLIC_URL + '/industries'}>Industries</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/blog'}>Blog</a></li>

                    <li><a href={process.env.PUBLIC_URL + '/Faq'}>FAQs</a></li>
                    <li><a href={process.env.PUBLIC_URL + '/Contact-us'}>Contact Us</a></li>                                                    

                </ul>
            </nav>
    
            )
    }
}