import React, { useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import './QuoteForm.css'; // Import the CSS module

const QuoteForm = ({ show, onClose }) => {
  const form = useRef();
  const [howDidYouHear, setHowDidYouHear] = useState("");


  const sendEmail = (e) => {
    e.preventDefault();

    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Lf9TOYpAAAAADGMNYN6HAwqoK-lbkuTPXJ8_3lI', { action: 'submit' }).then((token) => {
          console.log('reCAPTCHA token:', token); // Log the token to verify it's being generated

          // Add the reCAPTCHA token to the form as a hidden input
          const recaptchaInput = document.createElement('input');
          recaptchaInput.setAttribute('type', 'hidden');
          recaptchaInput.setAttribute('name', 'g-recaptcha-response');
          recaptchaInput.setAttribute('value', token);
          form.current.appendChild(recaptchaInput);

          emailjs.sendForm('service_xm1nqgq', 'template_twngvcm', form.current, 'tt_8iTyHFFW1ze-Sv')
            .then(
              (result) => {
                console.log('SUCCESS!', result.text);
                form.current.reset(); // Reset the form after successful submission
                onClose();
              },
              (error) => {
                console.log('FAILED...', error.text);
              }
            );

          // Remove the reCAPTCHA input element from the DOM
          form.current.removeChild(recaptchaInput);
        }).catch((error) => {
          console.error('Error executing reCAPTCHA:', error);
        });
      });
    } else {
      console.error('reCAPTCHA not loaded');
    }
  };

  return (
    <div className={`quote-form-container ${show ? 'show' : ''}`}>
      <div className="quote-form-overlay" onClick={onClose}></div>
      <div className="quote-form">
        <h2>Get A Quote</h2>
        <form ref={form} onSubmit={sendEmail}>
          <div className="form-group">
            <input type="text" name="user_name" placeholder="Your Name" required />
          </div>
          <div className="form-group">
            <input type="text" name="user_phone" placeholder="Phone Number" required />
          </div>
          <div className="form-group">
            <input type="text" name="user_company" placeholder="Your Company" required />
          </div>
          <div className="form-group">
            <input type="email" name="user_email" placeholder="Your Email" required />
          </div>
          <div className="form-group">
          <select
              name="user_hear_about"
              value={howDidYouHear}
              onChange={(e) => setHowDidYouHear(e.target.value)}
              required
            >
              <option value="" disabled>
                How Did You Hear About Us?
              </option>
              <option value="internet">Internet</option>
              <option value="google">Google</option>
              <option value="website">Website</option>
              <option value="linkedin">LinkedIn</option>
              <option value="current_customer">Current Customer</option>
              <option value="referral">Referral</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <textarea name="project_description" placeholder="Project Description" rows="6" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default QuoteForm;
