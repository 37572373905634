import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../layout/Header';

export const Contact_02 = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6Lf9TOYpAAAAADGMNYN6HAwqoK-lbkuTPXJ8_3lI', { action: 'submit' }).then((token) => {
                    console.log('reCAPTCHA token:', token); // Log the token to verify it's being generated

                    // Add the reCAPTCHA token to the form as a hidden input
                    const recaptchaInput = document.createElement('input');
                    recaptchaInput.setAttribute('type', 'hidden');
                    recaptchaInput.setAttribute('name', 'g-recaptcha-response');
                    recaptchaInput.setAttribute('value', token);
                    form.current.appendChild(recaptchaInput);

                    emailjs.sendForm('service_xm1nqgq', 'template_a69g8xr', form.current, 'tt_8iTyHFFW1ze-Sv')
                        .then(
                            (result) => {
                                console.log('SUCCESS!', result.text);
                                form.current.reset(); // Reset the form after successful submission
                            },
                            (error) => {
                                console.log('FAILED...', error.text);
                            }
                        );

                    // Remove the reCAPTCHA input element from the DOM
                    form.current.removeChild(recaptchaInput);
                }).catch((error) => {
                    console.error('Error executing reCAPTCHA:', error);
                });
            });
        } else {
            console.error('reCAPTCHA not loaded');
        }
    };

    return (
        <div className="site-main">
            <Header />
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Contact Us</h1>
                                </div>
                                <div className="breadcrumb-wrapper">
                                    <span>
                                        <a title="Homepage" href={process.env.PUBLIC_URL + '/Single_blog'}>
                                            <i className="ti ti-home" />&nbsp;&nbsp;Home
                                        </a>
                                    </span>
                                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                    <span>Contact Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ttm-row contact-section clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="section-title with-desc text-center clearfix">
                                <div className="title-header">
                                    <h5>Contact Us</h5>
                                    <h2 className="title">Drop us a line</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form ref={form} onSubmit={sendEmail} className="row ttm-quote-form clearfix">
                        <input type="text" name="honeypot" style={{ display: 'none' }} />
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <input name="user_name" type="text" className="form-control bg-white border" placeholder="Full Name*" required />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <div className="form-group">
                                <input name="user_phone" type="text" placeholder="Phone Number*" required className="form-control bg-white border" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <div className="form-group">
                                <input name="user_email" type="email" placeholder="Email Address*" required className="form-control bg-white border" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12">
                            <div className="form-group">
                                <textarea name="message" rows={5} placeholder="Write A Message..." required className="form-control bg-white border" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="text-center">
                                <button type="submit" id="submit" className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor mt-5 res-991-mt-20">
                                    Submit Message
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <div className="ttm-row contact-box-section ttm-bgcolor-grey clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-4 res-991-mb-60 res-991-mt-30">
                            <div className="featured-icon-box style8 text-center box-shadow">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                        <i className="far fa-envelope" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h5>Email</h5>
                                    </div>
                                    <div className="featured-desc">
                                        <p>
                                            <a href="mailto:info@eojcorp.com">info@eojcorp.com</a><br />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 res-991-mb-60">
                            <div className="featured-icon-box style8 text-center box-shadow">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                        <i className="fa fa-phone" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h5>Phone</h5>
                                    </div>
                                    <div className="featured-desc">
                                        <p>
                                            +01-4488-64585458<br />
                                            11415(12456)2424
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="featured-icon-box style8 text-center box-shadow">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md rounded-circle">
                                        <i className="ti ti-target" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h5>Address</h5>
                                    </div>
                                    <div className="featured-desc">
                                        <p>
                                        833 E Arapaho Rd Suite 211<br />
                                        Richardson, TX 75081
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="ttm-row map-section clearfix">
                <div className="map-wrapper">
                    <iframe height="100%" width="100%" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3346.6138482096225!2d-96.7248256848177!3d32.9613513809188!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c21a8e11c48e3%3A0x9d1f8e8c38b4af30!2s833%20E%20Arapaho%20Rd%20%23211%2C%20Richardson%2C%20TX%2075081%2C%20USA!5e0!3m2!1sen!2sus!4v1591859311334!5m2!1sen!2sus"></iframe>
                </div>
            </section>
        </div>
    );
};

export default Contact_02;
