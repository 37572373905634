import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import CountUp from "react-countup";
import Sidebar from "./Sidebar";
import Header from "../layout/Header";

class ServicePage extends Component {
  render() {
    const { title, description, image } = this.props;

    return (
      <div className="site-main">
        <Header />
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box text-center">
                  <div className="page-title-heading">
                    <h1 className="title">{title}</h1>
                  </div>
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        <i className="ti ti-home" />
                        &nbsp;&nbsp;Home
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>{title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-7 content-area order-lg-2">
                <div className="ttm-service-single-content-area">
                  <div className="ttm_single_image-wrapper mb-35">
                    <img className="img-fluid" src={image} alt={title} />
                  </div>
                  <div className="ttm-service-description">
                    <h3>{title}</h3>
                    <p>{description}</p>
                    <div className="row no-gutters box-shadow mb-35">
                      <div className="col-md-4 col-sm-4">
                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                          <div className="ttm-fid-left">
                            <div className="ttm-fid-icon-wrapper">
                              <i className="flaticon flaticon-global-1 ttm-textcolor-skincolor" />
                            </div>
                          </div>
                          <div className="ttm-fid-contents text-left">
                            <h4 className="ttm-fid-inner">
                              <CountUp start={0} end={15} duration={30} /> +
                            </h4>
                            <h3 className="ttm-fid-title">Years Experience</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                          <div className="ttm-fid-left">
                            <div className="ttm-fid-icon-wrapper">
                              <i className="flaticon flaticon-padlock ttm-textcolor-skincolor" />
                            </div>
                          </div>
                          <div className="ttm-fid-contents text-left">
                            <h4 className="ttm-fid-inner">
                              <CountUp start={0} end={1490} duration={30} /> +
                            </h4>
                            <h3 className="ttm-fid-title">Happy Clients</h3>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-4">
                        <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                          <div className="ttm-fid-left">
                            <div className="ttm-fid-icon-wrapper">
                              <i className="flaticon flaticon-developer ttm-textcolor-skincolor" />
                            </div>
                          </div>
                          <div className="ttm-fid-contents text-left">
                            <h4 className="ttm-fid-inner">
                              <CountUp start={0} end={815} duration={30} /> +
                            </h4>
                            <h3 className="ttm-fid-title">New Customers</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3>Why Choose Us?</h3>
                    <div className="row mt-35">
                      <div className="col-lg-12">
                        <div className="row m-0 box-shadow spacing-11">
                          <div className="col-md-6 col-lg-12 col-xl-6">
                            <div className="ttm_single_image-wrapper">
                              <img
                                className="img-fluid"
                                src="images/why-choose-us.jpg"
                                alt="Why Choose Us"
                              />
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-12 col-xl-6">
                            <p className="mb-30 res-1199-mt-20">
                              Offering top-notch solutions across various IT
                              domains, ensuring quality and efficiency in every
                              project....
                            </p>
                            <ul className="ttm-list ttm-list-style-icon">
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Expert team with extensive experience in
                                  various IT domains
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Tailored solutions to meet your specific
                                  business needs
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Commitment to quality and customer
                                  satisfaction
                                </span>
                              </li>
                              <li>
                                <i className="fa fa-check-circle ttm-textcolor-skincolor" />
                                <span className="ttm-list-li-content">
                                  Innovative approaches to enhance business
                                  efficiency
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 widget-area">
                <Sidebar />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServicePage;
