import React, { Component } from 'react';
import Header from '../layout/Header';

export class Services_02 extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h1 className="title">Our Services</h1>
                                    </div>
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'}><i className="ti ti-home" />&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                        <span>Services</span>
                                    </div>  
                                </div>
                            </div>  
                        </div>  
                    </div>                      
                </div>
                <section className="ttm-row grid-section clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="section-title with-desc text-center clearfix">
                                    <div className="title-header">
                                        <h5>Fast Growing Services</h5>
                                        <h2 className="title">Providing High-Performance Services For Multiple <span>Information &amp; Technologies!</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {this.renderServiceBox('Web Development and Design', 'images/services-01.jpg', 'Creating stunning, responsive websites tailored to your business needs.')}
                            {this.renderServiceBox('RPA Implementation and Customization', 'images/services-02.jpg', 'Automating repetitive tasks to enhance efficiency and reduce costs.')}
                            {this.renderServiceBox('Mobile App Development', 'images/services-03.jpg', 'Developing user-friendly mobile apps for Android and iOS platforms.')}
                            {this.renderServiceBox('E-Commerce Development', 'images/services-04.jpg', 'Building secure and scalable e-commerce solutions to grow your business online.')}
                            {this.renderServiceBox('API Development and 3rd Party Integration', 'images/services-05.jpg', 'Integrating third-party services seamlessly with robust APIs.')}
                            {this.renderServiceBox('Custom Software Development', 'images/services-06.jpg', 'Creating custom software solutions tailored to your specific business requirements.')}
                            {this.renderServiceBox('SaaS - Software as a Service', 'images/services-07.jpg', 'Delivering scalable and flexible SaaS solutions for diverse business needs.')}
                            {this.renderServiceBox('Automated Testing and QA Services', 'images/services-08.jpg', 'Ensuring the highest quality of your software with our automated testing services.')}
                            {this.renderServiceBox('Business Process Automation', 'images/services-09.jpg', 'Streamlining and optimizing your business processes with automation.')}
                            {this.renderServiceBox('CRM Implementation and Customization', 'images/services-10.jpg', 'Implementing and customizing CRM systems to boost customer relationships.')}
                            {this.renderServiceBox('Cyber Security Services', 'images/services-11.jpg', 'Protecting your digital assets with comprehensive cybersecurity solutions.')}
                            {this.renderServiceBox('DevOps, CICD and Cloud Services', 'images/services-12.jpg', 'Enhancing your IT infrastructure with our DevOps and cloud services.')}
                            {this.renderServiceBox('Database Management and Optimization', 'images/services-13.jpg', 'Managing and optimizing your databases for peak performance.')}
                            {this.renderServiceBox('Data Analyst and Visualization', 'images/services-14.jpg', 'Transforming your data into actionable insights with our analysis and visualization services.')}
                            {this.renderServiceBox('IT Support Maintenance', 'images/services-15.jpg', 'Providing reliable IT support and maintenance services to keep your systems running smoothly.')}
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    renderServiceBox(title, image, description) {
        return (
            <div className="col-md-6 col-lg-4">
                <div className="featured-imagebox featured-imagebox-services style1 mb-30">
                    <div className="featured-thumbnail">
                        <img className="img-fluid" src={image} alt="image" />
                    </div> 
                    <div className="featured-content box-shadow">
                        <div className="featured-title">
                            <h5><a href={process.env.PUBLIC_URL + '/' + title.replace(/\s+/g, '_')}>{title}</a></h5>
                        </div>
                        <div className="featured-desc">
                            <p>{description}</p>
                        </div>
                        <a className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-2" href={process.env.PUBLIC_URL + '/' + title.replace(/\s+/g, '_')}>Read More <i className="ti ti-angle-double-right" /></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services_02;
