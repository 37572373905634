import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBHamburgerToggler } from 'mdbreact';

class Mobilemenu extends Component {
  state = {
    collapse1: false,
  };

  toggleSingleCollapse = (collapseId) => () => {
    this.setState((prevState) => ({
      [collapseId]: !prevState[collapseId],
    }));
  };

  render() {
    return (
      <MDBNavbar>
        <MDBHamburgerToggler id="hamburger1" onClick={this.toggleSingleCollapse('collapse1')} />
        <MDBCollapse isOpen={this.state.collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem active>
              <MDBNavLink to="/">Home</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem active>
              <MDBNavLink to="/about-us">About Us</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="/services">Services</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
              <MDBNavLink to="/industries">Industries</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
              <MDBNavLink to="/blog">Blog</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
              <MDBNavLink to="/Faq">FAQs</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
              <MDBNavLink to="/contact-us">Contact Us</MDBNavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
    );
  }
}

export default Mobilemenu;
