import React, { Component } from 'react';

export class Sidebar extends Component {
    render() {     
        return (            
            <div>
                <div className="widget widget-nav-menu">
                    <ul className="widget-menu">
                        <li><a activeClassName="active" href={process.env.PUBLIC_URL + '/web_development_and_design'}>Web Development and Design</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/rpa_implementation_and_customization'}>RPA Implementation and Customization</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/mobile_app_development'}>Mobile App Development</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/e-commerce_development'}>E-Commerce Development</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/api_development_and_3rd_party_integration'}>API Development and 3rd Party Integration</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/custom_software_development'}>Custom Software Development</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/saas_-_software_as_a_service'}>SaaS - Software as a Service</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/automated_testing_and_qa_services'}>Automated Testing and QA Services</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/business_process_automation'}>Business Process Automation</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/crm_implementation_and_customization'}>CRM Implementation and Customization</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/cyber_security_services'}>Cyber Security Services</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/devops,_cicd_and_cloud_services'}>DevOps, CICD and Cloud Services</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/database_management_and_optimization'}>Database Management and Optimization</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/data_analyst_and_visualization'}>Data Analyst and Visualization</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/it_support_maintenance'}>IT Support Maintenance</a></li>
                    </ul>
                </div>
                <div className="widget widget_media_image">
                    <div className="banner-img-box ttm-textcolor-white text-left">
                        <div className="featured-content featured-content-banner">
                            <i className="flaticon flaticon-call" />
                            <div className="featured-title ttm-box-title">
                                <h5>How Can We Help?</h5>
                            </div>
                            <div className="featured-desc">
                                <p>If you need any help, please<br /> feel free to contact us.</p>
                            </div>
                            <ul>
                                <li><i className="fa fa-phone" />+1234567890</li>
                                <li><i className="far fa-envelope" /><a href="mailto:info@example.com">info@example.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>                        
            </div>
        );
    }
}

export default Sidebar;
